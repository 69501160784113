@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    /* @apply bg-gray-50;
    @apply dark:bg-gray-900; */
    /* @apply text-justify; */
  }

  body,
  p,
  li {
    @apply text-lg md:text-xl;
  }
  nav li {
    @apply text-xl;
  }
  /* section {
    @apply pt-16;
    @apply pb-8;
    @apply min-h-3/4;
  } */

  .background-gradient {
    background-image: radial-gradient(
        at 10% 90%,
        rgb(0, 100, 100, 0.05) 0,
        transparent 100%
      ),
      radial-gradient(at 90% 10%, rgb(0, 0, 100, 0.05) 0, transparent 80%);
  }

  .dark-background-gradient {
    /* @apply bg-scroll; */
    /* @apply bg-fixed md:bg-scroll; */
    /* @apply bg-local; */
    background-image:
      /* linear-gradient(to right, rgb(0, 255, 0, 1) 0, rgb(0, 255, 255, 0.15) 0), */
      /* radial-gradient(at 10% 10%, rgb(0, 255, 0, .20) 0, transparent 95%), */ radial-gradient(
        circle at 0% 100%,
        rgb(0, 0, 150, 0.3) 0,
        transparent 33%
      ),
      radial-gradient(
        circle at 100% 100%,
        rgb(0, 0, 150, 0.3) 0,
        transparent 33%
      ),
      /* radial-gradient(at 10% 50%, rgb(255, 255, 255, 0.05) 0, transparent 100%), */
        /* radial-gradient(at 90% 50%, rgb(255, 255, 255, 0.05) 0, transparent 100%), */
        radial-gradient(
          ellipse at -10% 60%,
          rgb(108, 85, 159, 0.3) 0,
          transparent 50%
        ),
      radial-gradient(
        circle at 110% 40%,
        rgb(10, 0, 150, 0.3) 0,
        transparent 50%
      ),
      radial-gradient(
        circle at 0% 0%,
        rgb(108, 85, 159, 0.3) 0,
        transparent 33%
      ),
      radial-gradient(
          circle at 100% 0%,
          rgb(108, 85, 159, 0.3) 0,
          transparent 33%
        )
        /* radial-gradient(at 120% 110%, rgb(0, 100, 0, 0.2) 0, transparent 95%) */;
  }

  .my-background {
    /* @apply bg-gradient-to-bl bg-white dark:from-slate-800 dark:via-black dark:to-slate-900; */
    /* @apply bg-gradient-to-bl from-white via-gray-100 to-gray-100 dark:from-slate-800 dark:via-slate-900 dark:to-dark/10; */
    /* @apply mt-4 rounded-xl py-4 px-2 shadow hover:shadow-lg md:px-8; */
    @apply mt-4 rounded-3xl bg-white p-4 shadow dark:border dark:border-gray-700 dark:bg-slate-600/30 dark:shadow-none;
  }

  .section {
    @apply bg-opacity-50;
    @apply bg-gradient-to-br;
    @apply from-white via-gray-100 to-gray-100;
    @apply dark:from-slate-800 dark:via-slate-800 dark:to-dark;
    @apply mt-4 rounded-xl py-4 px-2 shadow hover:shadow-lg md:px-8;
  }

  button {
    @apply uppercase;
  }

  .prose {
    /* @apply duration-1000 */
  }

  a,
  button {
    @apply underline decoration-1 underline-offset-8 hover:decoration-2;
    /* group */

    /* ::after('<span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-1 bg-sky-600"></span>') */
  }
  dl dd {
    display: inline;
    margin: 0;
  }

  dl dd:after {
    display: block;
    content: "";
  }

  dl dt {
    display: inline-block;
    min-width: 100px;
  }
}
